import { Validators } from '@angular/forms';

export const labelsStep5 = {
  needPropane: '¿Necesita Propano?',
  needGlassAndFilterDryer: '¿Necesita Visor de Líquito y Filtro Secador?',
  needThermostat: '¿Necesita Termostato?',
  needWeldingRods: '¿Necesita Varillas de Soldar?',
  weldingRodsAmount: 'Cantidad de Varillas de Soldar',
};

export const step5Controls = {
  needPropane: ['false'],
  needGlassAndFilterDryer: ['false'],
  needThermostat: ['false'],
  needWeldingRods: ['false'],
  weldingRodsAmount: [null],
};

export const yesOrNotOptions = [
  { value: 'true', label: 'Si' },
  { value: 'false', label: 'No' },
];
