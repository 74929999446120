<div class="content-wrapper">
    <div class="header-wrapper--with-total">
        <div class="header-title-wrapper">
            <h1 class="text-primary"> Cotizaciones </h1>
        </div>
        <h1> {{'Total: $' + total}}  </h1>
        <div class="tab-menu-wrapper">
            <div class="tab-option" *ngFor="let tabOption of tabMenuOptions" [ngClass]="{'selected': tabSelected === tabOption.key}"
            (click)="tabSelected = tabOption.key; setExportationDataConfig(tabOption.key); onClickTab()">
                <span>{{tabOption.label}}</span>
            </div>
        </div>
    </div>
    <div class="table-external-wrapper">
        <p-table class="quotation-table" *ngIf="!isLoading"
            [rows]="quotationsTable" 
            [columns]="tableColumns" 
            [innerIcons]="tableIcons" 
            [innerActions]="tableActions"
            [pagination]="tablePagination" 
            (changeCurrentPage)="handleChangePage($event)"
            [resultLabel]="'Cotizaciones'" 
            [showLoading]="isFetchingData"
            [isExporting]="isExporting"
            (onSorting)="onSortTable($event)"
            [hasSearch]="true" 
            [searchForm]="searchForm"
            (onSearchChange)="searchFormChanges($event)" 
            [filterActionLabel]="filterLabel"
            [actionsTooltips]="actionsTooltips"
            [showActionsAtStart]="true"
            [staticRowActions]="true"
            columnMode="force" 
            [exportDataConfig]="exportDataConfig"
            [topActions]="topActions"
            [topIcons]="topIcons"
            [enableAutoSearch]="true"
            [topActionsWithLabels]="true"
            [hasTopActions]="showTopActions"
            [additionActionLabel]="'Agregar nueva'"
            (onDownloadAction)="getReportData()">
        </p-table>
    </div>

    <prt-modal [displayButton]="false" size="sm" [isActive]="showDeletionModal" (onAction)="onDeleteModalStatus($event)"
    [hasCloseButton]="false">
        <div class="modal-wrapper" *ngIf="canBeDeleted">
            <h2 style="text-align: center" class="text-primary"> Confirmación </h2>
            <span class="paragraph-1">¿Estás seguro que deseas eliminar esta cotización?</span>
            <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
                <prt-button type="outline" [callback]="cancelDeletion">
                    Cancelar
                </prt-button>
                <prt-button [isInverted]="true" type="solid" [callback]="confirmDeletion">
                    Eliminar
                </prt-button>
            </div>
        </div>

        <div class="modal-wrapper" *ngIf="!canBeDeleted">
            <h2 style="text-align: center" class="text-primary"> Error </h2>
            <span class="paragraph-1">Esta cotización no puede ser eliminada</span>
            <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
                <prt-button type="outline" [callback]="cancelDeletion">
                    Aceptar
                </prt-button>
            </div>
        </div>
    </prt-modal>
    <prt-modal [displayButton]="false" size="sm" [isActive]="showCloneModal" (onAction)="onCloneModalConfirmation($event)" [hasCloseButton]="false">
        <div class="modal-wrapper">
          <h2 style="text-align: center" class="text-primary">Confirmación</h2>
          <span class="paragraph-1">¿Estás seguro que deseas duplicar esta cotización?</span>
          <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
            <prt-button type="outline" [callback]="cancelClone">Cancelar</prt-button>
            <prt-button [isInverted]="true" type="solid" [callback]="confirmCloning">Duplicar</prt-button>
          </div>
        </div>
      </prt-modal>
</div>