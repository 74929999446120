<!-- <div class="loading-container">
    <div class="pulse">
        {{ loadingMessage }}
    </div>
</div> -->
<div class="loading-container" *ngIf="show" [ngClass]="{ 'hide' : !show }">
    <div class="pulse">
      {{ loadingMessage }}
    </div>
  </div>
