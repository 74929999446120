import { Injectable } from '@angular/core';
import endpoints from 'src/app/constants/endpoints';
import { BaseRequest } from 'src/app/models/request';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class MarksService {
  constructor(private apiService: ApiService) {}

  getAll(query?: object): Promise<BaseRequest<string[]>> {
    return this.apiService.get<BaseRequest<string[]>>(`${endpoints.PRODUCTS}/air-conditioning-brands`, query);
  }
}
