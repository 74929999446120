import { Component, OnInit } from '@angular/core';
import { labelsStep3, yesOrNotOptions } from './step3.config';
import { PrtOptionDropdownModel, PrtOptionRadioButtonModel } from '@protostech/protos-lib/v2';
import { FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { FormUtilsService } from 'src/app/services/utils/form-utils.service';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss'],
})
export class Step3Component implements OnInit {
  labels = labelsStep3;
  yesOrNotOptions: PrtOptionRadioButtonModel[] = yesOrNotOptions;
  airBaseModelOptions: PrtOptionDropdownModel[] = [];
  destroy$ = new Subject<void>();
  showInputs = {
    airBaseModel: false,
    airBasesAmount: false,
    needAirBase: false,
  };

  constructor(
    private formUtilsService: FormUtilsService,
    private productsService: ProductsService,
    public formGroup: FormGroup,
  ) {
    //
  }

  ngOnInit(): void {
    this.subscribeToEvents();
    this.fillForm();
  }

  private async fillForm() {
    const needAirBase = this.formGroup.get('needAirBase');
    if (needAirBase?.value) {
      this.changeAirBaseModel(needAirBase.value);
      this.changeAirBasesAmount(needAirBase.value);
    } else {
      needAirBase?.setValue('false');
    }
    await this.fillAirBaseModelOptions();
    this.showInputs.needAirBase = this.airBaseModelOptions.length > 0;
    this.showAirBaseInputs(needAirBase?.value);
  }

  private subscribeToEvents() {
    this.onNeedAirBaseChange();
  }

  private onNeedAirBaseChange() {
    const needAirBase = this.formGroup.get('needAirBase');
    needAirBase?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.changeAirBaseModel(value);
      this.changeAirBasesAmount(value);
      this.showAirBaseInputs(value);
    });
  }

  private async changeAirBaseModel(value: string) {
    if (value === 'true') {
      await this.fillAirBaseModelOptions();
    } /* else {
      this.airBaseModelOptions = [];
    } */
    this.formUtilsService.toggleValidators(this.formGroup, 'airBaseModel', value);
  }

  private changeAirBasesAmount(value: string) {
    const airBasesAmount = this.formGroup.get('airBasesAmount');
    if (value === 'true') {
      if (!airBasesAmount?.value || airBasesAmount?.value === '') {
        airBasesAmount?.setValue(4);
      }
    } else {
      airBasesAmount?.reset();
    }
    this.formUtilsService.toggleValidators(this.formGroup, 'airBasesAmount', value);
  }

  private showAirBaseInputs(needAirBaseValue: string) {
    this.showInputs.airBaseModel = needAirBaseValue === 'true' && this.airBaseModelOptions.length > 0;
    this.showInputs.airBasesAmount = needAirBaseValue === 'true' && this.airBaseModelOptions.length > 0;
  }

  async fillAirBaseModelOptions() {
    const response = await this.productsService.getAirBaseModels();
    this.airBaseModelOptions = response.map((option: any) => ({ label: option.name, value: option.id }));
  }
}
