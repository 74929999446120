import { Validators } from '@angular/forms';

export const labelsStep3 = {
  needAirBase: '¿Necesita Base del Aire?',
  airBaseModel: ' Modelo de la Base',
  airBasesAmount: 'Cantidad de Bases',
  needLubricant: '¿Necesita Lubricante?',
  needTape: '¿Necesita Tape?',
  needPoFoam: '¿Necesita Foam de Poliuretano?',
};

export const step3Controls = {
  needAirBase: ['false'],
  airBaseModel: [null],
  airBasesAmount: [null],
  needLubricant: ['false'],
  needTape: ['false'],
  needPoFoam: ['false'],
};

export const yesOrNotOptions = [
  { value: 'true', label: 'Si' },
  { value: 'false', label: 'No' },
];
