import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { labelsStep0, placeholders } from './step0.config';
import { Subject, takeUntil } from 'rxjs';
import { PrtOptionDropdownModel } from '@protostech/protos-lib/v2';
import { MarksService } from 'src/app/services/marks/marks.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { Product } from 'src/app/models/products';

@Component({
  selector: 'app-step0',
  templateUrl: './step0.component.html',
  styleUrls: ['./step0.component.scss'],
})
export class Step0Component implements OnInit, OnDestroy {
  labels = labelsStep0;
  placeholders = placeholders;
  destroy$ = new Subject<void>();
  markOptions: PrtOptionDropdownModel[] = [];
  modelOptions: PrtOptionDropdownModel[] = [];

  constructor(
    private marksService: MarksService,
    private productsService: ProductsService,
    public formGroup: FormGroup,
  ) {
    //
  }

  async ngOnInit(): Promise<void> {
    this.subscribeToEvent();
    await this.fillMarks();
    this.fillForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private fillForm() {
    const brand = this.formGroup.get('brand');
    if (brand && brand.value !== '') {
      this.fillProducts(brand.value);
    }
  }

  private onBrandChange() {
    const brand = this.formGroup.get('brand');
    brand?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(async value => {
      if (value && value !== '') {
        await this.fillProducts(value);
      } else {
        this.modelOptions = [];
      }
    });
  }

  private onModelChange() {
    const airConditionerId = this.formGroup.get('airConditionerId');
    airConditionerId?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(async value => {
      const capacity = this.formGroup.get('capacity');
      if (value && value !== '') {
        const product = await this.productsService.getProductById(value);
        capacity?.setValue(product.capacity);
        capacity?.disable();
      } else {
        capacity?.enable();
        capacity?.reset();
      }
    });
  }

  private async fillMarks() {
    const response = (await this.marksService.getAll({
      limit: 0,
    })) as any;
    const options = response.map((brand: string) => ({ value: brand, label: brand }));
    this.markOptions = options;
  }

  private async fillProducts(brand: string) {
    const response = (await this.productsService.getAllProductModels({
      limit: 0,
      brand: brand,
    })) as any;
    const options = response.data.map((product: Product) => ({ value: product.id, label: product.name }));
    this.modelOptions = options;
  }

  subscribeToEvent() {
    this.onBrandChange();
    this.onModelChange();
  }
}
