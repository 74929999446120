<div class="resume__content-wrapper">
  <div class="resume__content-box">
    <div
      class="resume__top-box"
      [ngClass]="{ cancelled: quotationValues.status === 'CANCELED', paid: quotationValues.status === 'PAID' }"
    >
      <div class="resume__technician-img">
        <img
          [src]="quotationValues.User.picUrl ? quotationValues.User.picUrl : '../../../assets/icons/user-icon.svg'"
        />
      </div>
      <h2>Cotización</h2>
    </div>
    <div class="resume__header-wrapper">
      <div class="header__upper-box">
        <div class="header__order-number-box">
          <img src="../../../assets/icons/quotations-icon.svg" alt="quotation-icon" class="cursor-default" />
          <span class="paragraph-4"># {{ quotationValues.number }}</span>
        </div>
      </div>
      <span class="paragraph-6">{{ quotationValues.User.name }}</span>
    </div>
    <div class="body-wrapper">
      <div class="data-field-wrapper">
        <div class="data-field-box data-field-box--block">
          <h4>Cliente</h4>
          <span class="paragraph-2">{{ quotationValues.Client.name }}</span>
        </div>
        <div class="data-field-box data-field-box--half-size">
          <h4>Identificación</h4>
          <span class="paragraph-2">{{ clientValues.dni ? clientValues.dni : '-' }}</span>
        </div>
        <div class="data-field-box data-field-box--half-size">
          <h4>Teléfono</h4>
          <span class="paragraph-2">{{ clientValues.phone }}</span>
        </div>
        <div class="data-field-box data-field-box--block">
          <h4>Dirección</h4>
          <span class="paragraph-2">{{ clientValues.address }}</span>
        </div>
        <div class="data-field-box data-field-box--block">
          <h4>Correo electrónico</h4>
          <span class="paragraph-2">{{ clientValues.email }}</span>
        </div>
        <div class="data-field-box data-field-box--block">
          <h4>Nota</h4>
          <span class="paragraph-2">{{ quotationValues.note ? quotationValues.note : '-' }}</span>
        </div>
        <div class="quotation-resume-wrapper">
          <h3 class="items-resume-title">{{ switch ? 'Detalle' : 'Resumen' }}</h3>
          <div class="products-box" *ngIf="!switch">
            <span class="paragraph-2" *ngFor="let product of quotationValues.products">
              {{ product.name }}
            </span>
          </div>
          <div class="products-box" *ngIf="switch">
            <span class="paragraph-2" *ngFor="let product of quotationValues.products">
              {{ product.quantity + ' ' + product.unit + ' de ' + product.name }}
            </span>
          </div>
          <div class="ammount-box">
            <div class="ammount-item">
              <span class="paragraph-3">Subtotal</span>
              <span class="paragraph-2">${{ quotationValues.subtotal }}</span>
            </div>
            <div class="ammount-item">
              <span class="paragraph-3">Impuestos</span>
              <span class="paragraph-2">${{ quotationValues.tax }}</span>
            </div>
          </div>
          <div class="total-box">
            <span class="paragraph-3">Total</span>
            <span class="paragraph-2">${{ quotationValues.total }}</span>
          </div>
        </div>
      </div>
      <div class="warning-message-box" *ngIf="quotationValues.status === 'ISSUED'">
        <h4>Esta cotización será valida por 48 horas o según disponibilidad de inventario</h4>
      </div>
      <div class="footer-wrapper" *ngIf="quotationValues.status !== 'CANCELED' && quotationValues.status !== 'PAID'">
        <prt-button [isInverted]="true" type="solid" [callback]="handleMarkAsPaidModal">
          <h2>Marcar como pagada</h2>
        </prt-button>
        <prt-button color="secondary" [callback]="handleMarkAsCancelledModal" *ngIf="quotationValues.status !== 'PAID'">
          <h2>Rechazar cotización</h2>
        </prt-button>
      </div>
      <h1 class="payment-status" style="text-align: center" *ngIf="quotationValues.status === 'CANCELED'">Rechazada</h1>
      <h1 class="payment-status" style="text-align: center" *ngIf="quotationValues.status === 'PAID'">Pagada</h1>
      <h1 class="payment-status" style="text-align: center" *ngIf="quotationValues.status === 'ACCEPTED'">Aceptada</h1>
    </div>
  </div>
  <prt-modal
    [displayButton]="false"
    size="md"
    [isActive]="showPaymentModal && checkoutPrepared"
    (onAction)="onPaymentModalStatus($event)"
    [hasCloseButton]="false"
    [disableCloseOnOverlay]="true"
  >
    <div class="modal-wrapper modal-wrapper--payment">
      <div class="payment-modal__header-wrapper">
        <h2 style="text-align: center">Pago Cotización #{{ quotationValues.number }}</h2>
      </div>
      <h2>
        Total a pagar: <b class="text-primary">${{ quotationValues.total }}</b>
      </h2>
      <div appDynamicScript *ngIf="checkoutPrepared">
        <div>{{ checkoutId }}</div>
      </div>
      <form
        [action]="'/public/payment-check?quotationId=' + this.quotationId"
        class="paymentWidgets"
        data-brands="VISA MASTER"
      ></form>
      <div class="modal-buttons-wrapper">
        <prt-button color="secondary" [callback]="cancelPayment"> Cancelar </prt-button>
      </div>
    </div>
  </prt-modal>
  <prt-modal
    [displayButton]="false"
    size="sm"
    [isActive]="showRejectModal"
    (onAction)="onRejectModalStatus($event)"
    [hasCloseButton]="false"
    [disableCloseOnOverlay]="true"
  >
    <div class="modal-wrapper">
      <h2 style="text-align: center" class="text-primary"> Rechazar cotización </h2>
      <span class="paragraph-1">¿Desea rechazar la cotización?</span>
      <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
        <prt-button type="solid" [callback]="rejectQuotation"> Rechazar </prt-button>
        <prt-button color="secondary" [callback]="cancelReject"> Cancelar </prt-button>
      </div>
    </div>
  </prt-modal>
  <prt-modal
          [displayButton]="false"
          size="sm"
          [isActive]="showMarkAsPaidModal"
          (onAction)="onMarkAsPaidModalStatus($event)"
          [hasCloseButton]="false"
          [disableCloseOnOverlay]="true"
  >
    <div class="modal-wrapper">
      <h2 style="text-align: center" class="text-primary"> Marcar como pagada la cotización </h2>
      <span class="paragraph-1">¿Desea marcar como pagada la cotización?</span>
      <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
        <prt-button type="solid" [callback]="markAsPaid"> Aceptar </prt-button>
        <prt-button color="secondary" [callback]="cancelMarkAsPaid"> Cancelar </prt-button>
      </div>
    </div>
  </prt-modal>
  <prt-modal
          [displayButton]="false"
          size="sm"
          [isActive]="showMarkAsCancelledModal"
          (onAction)="onMarkAsCancelledModalStatus($event)"
          [hasCloseButton]="false"
          [disableCloseOnOverlay]="true"
  >
    <div class="modal-wrapper">
      <h2 style="text-align: center" class="text-primary"> Cancelar cotización </h2>
      <span class="paragraph-1">¿Desea cancelar la cotización?</span>
      <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
        <prt-button type="solid" [callback]="markAsCancelled"> Aceptar </prt-button>
        <prt-button color="secondary" [callback]="cancelMarkAsCancelled"> Cancelar </prt-button>
      </div>
    </div>
  </prt-modal>
</div>
