import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, PButtonComponent, PButtonModule, PTableModule } from '@protostech/protos-lib';
import { FormModule } from '@protostech/protos-lib';
import { LayoutModule } from '@protostech/protos-lib';
import { ModalModule } from '@protostech/protos-lib';
import { SideNavModule } from '@protostech/protos-lib';
import { TableModule } from '@protostech/protos-lib';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  PRT_FORM_ERROR_MESSAGE,
  PrtAccordionModule,
  PrtCheckboxModule,
  PrtDropdownModule,
  PrtFormBaseModule,
  PrtFormFieldModule,
  PrtFormGroupModule,
  PrtInputDateModule,
  PrtInputModule,
  PrtLabelModule,
  PrtRadioButtonModule,
  PrtTextareaModule,
  PrtToggleModule,
  extendPrtFormErrorManager,
} from '@protostech/protos-lib/v2';

export const COMPONENTS = [];

const PRT_FORM_ERROR_MESSAGE_OP = {
  required: 'El campo es requerido.',
  min: 'Valor minimo alcanzado',
  max: 'Valor máxima alcanzado',
  minlength: 'Longitud minima alcanzada.',
  maxlength: 'Longitud máxima alcanzada.',
  pattern: 'El formato no es correcto.',
  default: 'Hay un error en el campo',
  email: 'El formato de correo no es correcto.',
  dateMax: 'La fecha no puede ser mayor',
  dateMin: 'La fecha no puede ser menor',
};

@NgModule({
  declarations: [COMPONENTS],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgSelectModule,
    ButtonModule,
    FormModule,
    LayoutModule,
    ModalModule,
    SideNavModule,
    TableModule,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgSelectModule,
    ButtonModule,
    FormModule,
    LayoutModule,
    ModalModule,
    SideNavModule,
    TableModule,
    PTableModule,
    PButtonModule,
    PrtAccordionModule,
    PrtCheckboxModule,
    PrtDropdownModule,
    PrtFormBaseModule,
    PrtFormFieldModule,
    PrtFormGroupModule,
    PrtInputDateModule,
    PrtInputModule,
    PrtLabelModule,
    PrtRadioButtonModule,
    PrtTextareaModule,
    PrtToggleModule,
  ],
  providers: [
    {
      provide: PRT_FORM_ERROR_MESSAGE,
      useValue: extendPrtFormErrorManager({ ...PRT_FORM_ERROR_MESSAGE_OP }),
    },
  ],
})
export class SharedModule {}
