import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ability } from '@casl/ability';
import { An507FormRefInterface, DropdownField, TextBoxField, TextBoxPhoneField } from '@protostech/protos-lib';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { getError } from 'src/app/models/error';
import { TechnicianForm } from 'src/app/models/technician';
import { AuthService } from 'src/app/services';
import { TechniciansService } from 'src/app/services/technicians/technicians.service';
import validation from 'src/app/utils/validation';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @ViewChild('formTemplate', { static: false }) profileFormId: An507FormRefInterface | undefined;

  profileForm: TextBoxField[] | DropdownField[] | TextBoxPhoneField[] = [
    {
      controlType: 'textBox',
      key: 'name',
      type: 'text',
      value: '',
      label: 'Nombre y Apellido',
      required: true,
      width: 'lg',
      maxLength: 100,
      minLength: 2,
      regex: validation.namePattern,
      validationErrorMessage: 'El nombre no debe contener números',
      showErrorsUx: true,
    },
    {
      controlType: 'textBox',
      key: 'address',
      type: 'text',
      value: '',
      label: 'Dirección',
      required: false,
      width: 'lg',
      maxLength: 100,
      validationErrorMessage: 'Dirección inválida',
      showErrorsUx: true,
    },
    {
      controlType: 'textBox',
      key: 'dni',
      type: 'text',
      value: '',
      label: 'Identificación',
      required: false,
      width: 'lg',
      maxLength: 100,
      regex: validation.dniPattern,
      validationErrorMessage: 'Identificación inválida.',
      showErrorsUx: true,
    },
    {
      controlType: 'textBox',
      key: 'email',
      type: 'string',
      value: '',
      label: 'Correo electrónico',
      required: true,
      width: 'lg',
      maxLength: 100,
      regex: validation.emailPattern,
      validationErrorMessage: 'Correo electrónico inválido',
      showErrorsUx: true,
    },
    {
      controlType: 'textBoxPhone',
      key: 'phone',
      type: 'string',
      value: '',
      label: 'Número de contacto',
      required: true,
      width: 'lg',
      //regex: validation.phonePattern,
      validationErrorMessage: 'Número de contacto incorrecto',
      showErrorsUx: true,
    },
    {
      controlType: 'textBox',
      key: 'company',
      type: 'string',
      value: '',
      label: 'Compañía',
      required: false,
      width: 'lg',
      validationErrorMessage: 'Compañía inválida',
      showErrorsUx: true,
    },
    {
      controlType: 'textBox',
      key: 'oldPassword',
      type: 'password',
      value: '',
      label: 'Contraseña actual',
      required: false,
      width: 'lg',
      minLength: 6,
      maxLength: 30,
      validationErrorMessage: 'Contraseña debe contener más de 5 caracteres y menos de 31',
      showErrorsUx: true,
      // noMargin: true,
    },
    {
      controlType: 'textBox',
      key: 'password',
      type: 'password',
      value: '',
      label: 'Contraseña nueva',
      required: false,
      width: 'lg',
      minLength: 6,
      maxLength: 30,
      validationErrorMessage: 'Contraseña debe contener más de 5 caracteres y menos de 31',
      showErrorsUx: true,
      // noMargin: true,
    },
  ];

  isTechnicianFormValid = false;

  technicianFormData: any = {
    email: '',
    password: '',
    name: '',
    oldPassword: '',
  };

  isFormLoaded = false;
  technicianId: string | null = null;

  technicianValues: any = {
    createdAt: new Date(),
    email: '',
    name: '',
    id: '',
    address: '',
    dni: '',
    phoneCode: '',
    phone: '',
    picUrl: '',
    company: '',
    updatedAt: new Date(),
  };

  statusTranslations: Record<string, string> = {
    CREATED: 'Borrador',
    ISSUED: 'Enviada',
    ACCEPTED: 'Aceptada',
    PAID: 'Pagada',
    CANCELED: 'Cancelada',
  };

  loadedData = false;

  temporarySelectedImg = '';

  showModal = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ability: Ability,
    private techinicianService: TechniciansService,
    private authService: AuthService,
  ) {
    //
  }

  async ngOnInit(): Promise<void> {
    try {
      const me = await firstValueFrom(this.authService.getMe());
      const technician = await this.techinicianService.getTechnicianById(me.id);
      this.fillValues(technician);
      this.fillForm();
      this.loadedData = true;
    } catch (error) {
      console.error(error);
      this.router.navigate(['/login']);
    }
  }

  fillValues(data: any) {
    this.technicianValues = data;
  }

  async fillForm() {
    this.isFormLoaded = false;

    setTimeout(() => {
      this.profileFormId?.updateForm({
        email: this.technicianValues.email,
        password: '',
        name: this.technicianValues.name,
        address: this.technicianValues.address,
        dni: this.technicianValues.dni,
        phone: {
          dialCode: this.technicianValues.phoneCode,
          number: this.technicianValues.phone,
          countryCode: this.technicianValues.countryCode,
        },
        company: this.technicianValues.company,
      });
      this.isFormLoaded = true;
    });
  }

  //save Technician
  saveChanges = () => {
    if (this.technicianValues.id && this.isTechnicianFormValid) {
      if (
        (this.technicianFormData.oldPassword && !this.technicianFormData.password) ||
        (!this.technicianFormData.oldPassword && this.technicianFormData.password)
      ) {
        this.checkDuplicateErrorMessage(
          'Error',
          'Si desea cambiar su contraseña. Debe completar ambos campos de contraseña, caso contrario deje los campos vacíos.',
        );
        return;
      }
      const formData = {
        name: this.technicianFormData.name,
        email: this.technicianFormData.email,
        id: this.technicianValues.id,
        address: this.technicianFormData.address,
        company: this.technicianFormData.company,
        dni: this.technicianFormData.dni,
        phoneCode: this.technicianFormData.phone?.dialCode,
        phone: this.technicianFormData.phone?.number,
        countryCode: this.technicianFormData.phone?.countryCode,
        pic: this.technicianFormData.pic,
        password: this.technicianFormData.password,
        oldPassword: this.technicianFormData.oldPassword,
      };
      if (formData.oldPassword === '') {
        delete formData.oldPassword;
      }
      if (formData.password === '') {
        delete formData.password;
      }
      this.techinicianService
        .updateTechnician(formData)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Cambios guardados');
          // this.router.navigate(['/private/technicians']);
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code, false, true);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    } else {
      this.checkDuplicateErrorMessage('Por favor, complete los campos obligatorios:', '', true, false);
    }
  };

  profileFormChanges(evt: object) {
    if (!(evt instanceof Event)) {
      Object.assign(this.technicianFormData, { ...(evt as TechnicianForm) });
    }
  }

  profileFormISValid(evt: boolean) {
    this.isTechnicianFormValid = evt;
  }

  cancelChanges = () => {
    this.router.navigate(['/private/technicians']);
  };

  //Error Messages
  checkDuplicateErrorMessage(title: string, message: string, getFormErrors?: boolean, getBackendErrors?: boolean) {
    let duplicate: any;
    if (getFormErrors) {
      const errorMessage = this.getFormErrors();
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
    } else if (getBackendErrors) {
      const errorMessage = getError(message);
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
    } else {
      duplicate = this.toastr.findDuplicate(title, message, true, false);
    }
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  getFormErrors() {
    const error: string[] = [];
    this.profileForm.forEach(element => {
      const isInvalid = (this.profileFormId as any).getFormValidationErrorsByKey(element.key);
      if (isInvalid.length > 0) {
        error.push(element.label);
      }
    });
    return error.join(', ');
  }

  translateStatus(statusKey: string) {
    return this.statusTranslations[statusKey];
  }

  attachImg(img: any) {
    const reader = new FileReader();
    const file = img.target.files[0];
    reader.onload = e => {
      this.temporarySelectedImg = reader.result as string;
      this.technicianFormData.pic = img.target.files[0];
    };
    reader.readAsDataURL(file);
  }

  logOut = () => {
    this.showModal = true;
  };

  onModalStatus(evt: { isOpen: boolean }) {
    this.showModal = evt.isOpen;
  }

  returnDorita = () => {
    this.showModal = false;
  };

  confirmationExit = () => {
    this.authService.logout();
  };
}
