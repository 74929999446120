import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { An507FormRefInterface, BasicFormBase, DropdownField, TextBoxField } from '@protostech/protos-lib';
import { ToastrService } from 'ngx-toastr';
import { User, UserForm, Role, getRole } from 'src/app/models/auth';
import { getError } from 'src/app/models/error';
import { RolesService } from 'src/app/services/roles/roles.service';
import { UsersService } from 'src/app/services/users/users.service';
import validation from 'src/app/utils/validation';
import { Ability } from '@casl/ability';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { CreatedPdfService } from 'src/app/services/pdf/created-pdf.service';
import moment from 'moment';
import { ExportDataConfig } from '@protostech/protos-lib/lib/interfaces/export-data-config';
import { exportDataConfig } from 'src/app/models/export-config';
import { GLOBAL_CONFIG } from 'src/app/constants/global-config';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  @ViewChild('searchFormTemplate', { static: false }) searchFormId: An507FormRefInterface | undefined;
  @ViewChild('filterFormTemplate', { static: false }) filterFormId: An507FormRefInterface | undefined;

  userId: string | null = null;

  searchForm: TextBoxField[] = [
    {
      controlType: 'textBox',
      key: 'query',
      type: 'text',
      value: '',
      label: '',
      required: false,
      width: 'lg',
      noMargin: true,
      showInnerIcon: true,
      icon: '../assets/icons/search.svg',
      position: 'center',
      isSingleRow: true,
      placeholder: 'Escriba su búsqueda',
    },
  ];
  searchQuery: any = {
    page: 1,
    limit: 10,
  };

  userCanCreate = false;
  userCanUpdate = false;
  showAcceptButton = false;

  actionsTooltips = {
    deleteAction: 'Eliminar',
  };

  tableIcons = {
    modifyIcon: '../assets/icons/edit.svg',
    deleteIcon: '../assets/icons/delete.svg',
    downloadIcon: '../assets/icons/download-icon.svg',
  };

  tableActions: Record<string, unknown> = {
    modifyAction: (row: Record<string, unknown>) => this.editUser(row['id'] as string),
    deleteAction: (row: Record<string, unknown>) => this.deleteUser(row['id'] as string),
  };

  usersTable: any[] = [];

  tableColumns = [
    {
      prop: 'name',
      name: 'Nombre',
      minWidth: 400,
    },
    {
      prop: 'email',
      name: 'Email',
      minWidth: 270,
    },
    {
      prop: 'rol',
      name: 'Rol',
      minWidth: 250,
    },
    {
      prop: 'lastLogin',
      name: 'Última conexión',
      minWidth: 200,
      sortable: true,
      columnAlign: 'center',
    },
  ];

  tablePagination = {
    page: 1,
    limit: 10,
    count: 0,
  };

  showDeletionModal = false;
  isFetchingData = true;
  isExporting = false;
  showActionsButton = true;
  filterLabel: any;
  exportDataConfig: ExportDataConfig | undefined;
  dateFormat = GLOBAL_CONFIG.dateFormat;

  constructor(
    private userService: UsersService,
    private rolesService: RolesService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private ability: Ability,
    private pdfSvc: CreatedPdfService,
  ) {
    // if (ability.can('update', 'User')) {
    //   this.tableActions['modifyAction'] = (row: Record<string, unknown>) => this.editUser(row['id'] as string);
    // }
    if (ability.can('delete', 'User')) {
      this.tableColumns.push({
        prop: 'delete',
        name: '',
        sortable: false,
        minWidth: 50,
        columnAlign: 'center',
      });
    }
  }

  async ngOnInit() {
    this.fillTable();

    const roles = await this.rolesService.getAllRoles({
      limit: 0,
      sortKey: 'name',
      sortOrder: 'asc',
    });

    this.route.queryParamMap.subscribe(async params => {
      const sortBy = params.get('sortBy');
      const sortOrder = params.get('sortOrder');
      const page = Number(params.get('page'));

      this.buildQuery(sortBy, sortOrder, page);
      if (page) {
        this.tablePagination['page'] = page;
      }

      this.fillTable(sortBy, sortOrder);
    });

    this.canCreate();
    //this.tableActions['downloadAction'] = this.handleCreateReport;
    await this.setExportationDataConfig();
  }

  ngAfterViewInit() {
    this.searchForm[0].triggerOnEnter = this.searchOnEnter;
    this.searchForm[0].triggerOnBlur = this.searchOnBlur;
  }

  async setExportationDataConfig() {
    this.exportDataConfig = exportDataConfig;
    const columns = [
      {
        header: 'Nombre y Apellido',
        key: 'name',
        width: 20,
      },
      {
        header: 'Email',
        key: 'email',
        width: 20,
      },
      {
        header: 'Rol',
        key: 'rol',
        width: 20,
      },
      {
        header: 'Última conexión',
        key: 'lastLogin',
        width: 20,
      },
    ];
    this.searchQuery['limit'] = 0;
    const users = await this.userService.getAllUsers(this.searchQuery);
    const userReport = users.data.map(user => {
      const roleName = getRole(user.role.id);
      return {
        name: user.name,
        email: user.email,
        rol: roleName !== '' ? roleName : user.role.name,
        lastLogin: moment.utc(user.lastLogin).format(this.dateFormat),
      };
    });
    this.exportDataConfig.data = userReport;
    this.exportDataConfig.fileName = 'Usuarios';
    this.exportDataConfig.config.columns = columns;
    this.exportDataConfig.config.pdfConfigOptions = { pdfColumnWidth: [] };
    this.exportDataConfig.config.pdfConfigOptions.pdfColumnWidth = [200, 100, 100];
  }

  //Search
  buildQuery(sortKey?: string | null, sortOrder?: string | null, page?: number) {
    if (page) {
      Object.assign(this.searchQuery, page);
    }
    if (sortKey) {
      Object.assign(this.searchQuery, { sortKey, sortOrder });
    } else {
      Object.assign(this.searchQuery, { sortKey: 'name', sortOrder: 'asc' });
    }
  }

  searchOnEnter = (evt: any) => {
    if (evt.key === 'Enter') {
      const value = evt.target.value;
      this.updateSearchForm(value);
    }
  };

  searchOnBlur = (evt: any) => {
    this.updateSearchForm(evt.target.value);
  };

  updateSearchForm = (value: string) => {
    this.searchFormId?.form.setValue({ [this.searchForm[0].key]: value }, { emitEvent: true });
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsDirty();
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsTouched();
    this.search();
  };

  search = async () => {
    this.isFetchingData = true;
    if (!this.searchQuery['q']) {
      delete this.searchQuery['q'];
    }
    this.isFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        const users = await this.userService.getAllUsers(this.searchQuery);

        this.usersTable = users.data.map(user => {
          const roleName = getRole(user.role.id);
          return {
            id: user.id,
            name: user.name,
            email: user.email,
            rol: roleName !== '' ? roleName : user.role.name,
            lastLogin: moment.utc(user.lastLogin).format(this.dateFormat),
          };
        });

        this.tablePagination = { page: users.page, limit: users.limit, count: users.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isFetchingData = false;
    });
  };

  async searchFormChanges(evt: { query: string } | unknown) {
    if ((evt as { query: string }).query) {
      this.searchQuery = {
        ...this.searchQuery,
        ['q']: (evt as { query: string }).query,
      };
    } else {
      this.resetSearchQuery();
    }
  }

  resetSearchQuery() {
    delete this.searchQuery['q'];
    this.searchQuery = {
      ...this.searchQuery,
      page: 1,
      limit: 10,
    };
  }

  addUser = () => {
    this.router.navigate(['/private/admin/users/new']);
  };

  deleteUser(userId: string) {
    this.userId = userId;
    this.showDeletionModal = true;
  }

  cancelDeletion = () => {
    this.userId = null;
    this.showDeletionModal = false;
  };

  confirmDeletion = () => {
    if (this.userId && this.userId !== '') {
      this.showActionsButton = false;
      this.userService
        .deleteUser(this.userId)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Usuario eliminado');
          this.fillTable();
          this.showDeletionModal = false;
          this.userId = null;
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code, true);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
          this.showActionsButton = true;
        });
    }
  };

  editUser = async (userId: string) => {
    this.router.navigate(['/private/admin/users/edit'], { queryParams: { userId: userId } });
  };

  checkDuplicateErrorMessage(title: string, message: string, getBackendErrors?: boolean) {
    let duplicate: any;
    if (getBackendErrors) {
      const errorMessage = getError(message);
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
    } else {
      duplicate = this.toastr.findDuplicate(title, message, true, false);
    }
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  async fillTable(sortKey?: string | null, sortOrder?: string | null) {
    this.isFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        const users = await this.userService.getAllUsers({
          page: this.tablePagination.page,
          limit: this.tablePagination.limit,
          sortKey: sortKey ? sortKey : 'name',
          sortOrder: sortOrder ? sortOrder : 'asc',
        });

        this.usersTable = users.data.map(user => {
          const roleName = getRole(user.role.id);
          return {
            id: user.id,
            lastLogin: moment.utc(user.lastLogin).format(this.dateFormat),
            name: user.name,
            email: user.email,
            rol: roleName !== '' ? roleName : user.role.name,
          };
        });

        this.tablePagination = { page: users.page, limit: users.limit, count: users.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isFetchingData = false;
    });
  }

  handleChangePage(page: number) {
    this.tablePagination.page = page;
    this.router.navigate(['/private/admin/users'], { queryParams: { page }, queryParamsHandling: 'merge' });
    if (this.searchQuery['q']) {
      this.search();
    }
  }

  async onSortTable(evt: { dir: string; column: string }) {
    this.tablePagination.page = 1;
    this.router
      .navigate(['/private/admin/users'], {
        queryParams: { sortBy: evt.column, sortOrder: evt.dir, page: 1 },
        queryParamsHandling: 'merge',
      })
      .then(() => this.setExportationDataConfig());
  }

  goToRolesView = () => {
    this.router.navigate(['/private/admin/roles']);
  };

  onDeleteModalStatus(evt: { isOpen: boolean }) {
    this.showDeletionModal = evt.isOpen;
  }

  canCreate() {
    this.userCanCreate = this.ability.can('create', 'User');
  }

  canUpdate(userId?: string) {
    if (this.ability.cannot('update', 'User')) {
      this.userCanUpdate = false;
      this.showAcceptButton = true;
    } else {
      this.userCanUpdate = true;
      this.showAcceptButton = false;
    }
  }

  /**
   * @deprecated
   */
  handleCreateReport = async () => {
    this.isExporting = true;
    this.searchQuery['limit'] = 0;
    const users = await this.userService.getAllUsers(this.searchQuery);

    const userReport = users.data.map(user => {
      const roleName = getRole(user.role.id);
      return {
        name: user.name,
        email: user.email,
        rol: roleName !== '' ? roleName : user.role.name,
        lastLogin: moment.utc(user.lastLogin).format(this.dateFormat),
      };
    });
    const pdfDefinition: any = this.pdfSvc.createdReportUser(userReport);
    pdfMake.createPdf(pdfDefinition).download('Usuarios.pdf');
    this.isExporting = false;
    this.searchQuery['limit'] = 10;
  };
}
