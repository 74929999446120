import { Validators } from '@angular/forms';

export const labelsStep4 = {
  needSurgeProtector: '¿Necesita Protector de Voltaje?',
  needPowerCable: '¿Necesita Cable Potencia?',
  needCondensatePumps: '¿Necesita Bombas de Condensado?',
  condensatePumpsAmount: 'Cantidad de Bombas',
  condensatePumpsModel: 'Modelo de Bomba',
};

export const step4Controls = {
  needSurgeProtector: ['false'],
  needPowerCable: ['false'],
  needCondensatePumps: ['false'],
  condensatePumpsAmount: [null],
  condensatePumpsModel: [null],
};

export const yesOrNotOptions = [
  { value: 'true', label: 'Si' },
  { value: 'false', label: 'No' },
];
