import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { An507FormRefInterface, TextBoxField } from '@protostech/protos-lib';
import { ToastrService } from 'ngx-toastr';
import { Ability } from '@casl/ability';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { CreatedPdfService } from 'src/app/services/pdf/created-pdf.service';
import { QuotationsService } from 'src/app/services/quotations/quotations.service';
import moment from 'moment-timezone';
import { Quotation, QuotationForm } from 'src/app/models/quotation';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services';

import { TechniciansService } from 'src/app/services/technicians/technicians.service';
import { ExportDataConfig } from '@protostech/protos-lib/lib/interfaces/export-data-config';
import { exportDataConfig } from 'src/app/models/export-config';
import { GLOBAL_CONFIG } from 'src/app/constants/global-config';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-quotations',
  templateUrl: './quotations.component.html',
  styleUrls: ['./quotations.component.scss'],
})
export class QuotationsComponent implements OnInit {
  @ViewChild('searchFormTemplate', { static: false }) searchFormId: An507FormRefInterface | undefined;
  @ViewChild('filterFormTemplate', { static: false }) filterFormId: An507FormRefInterface | undefined;

  searchForm: TextBoxField[] = [
    {
      controlType: 'textBox',
      key: 'query',
      type: 'text',
      value: '',
      label: '',
      required: false,
      width: 'lg',
      noMargin: true,
      showInnerIcon: true,
      icon: '../assets/icons/search.svg',
      position: 'center',
      isSingleRow: true,
      placeholder: 'Escriba su búsqueda',
    },
  ];
  searchQuery: any = {
    page: 1,
    limit: 10,
    sortKey: 'createdAt',
    sortOrder: 'desc',
    status: 'ISSUED',
  };

  userCanCreate = false;
  userCanUpdate = false;
  showAcceptButton = false;

  topActions = {
    additionAction: () => this.addQuotation(),
  };

  tableIcons = {
    modifyIcon: '../assets/icons/edit.svg',
    deleteIcon: '../assets/icons/delete.svg',
    downloadIcon: '../assets/icons/download-icon.svg',
    extraAction2Icon: '../assets/icons/clone.svg',
  };

  //return !!this.topIcons?.downloadIcon && (this.topActions?.downloadAction || this.exportDataConfig);

  topIcons = {
    additionIcon: '../../../assets/icons/add-icon.svg',
    downloadIcon: '../assets/icons/download-icon.svg',
  };

  tableActions: Record<string, unknown> = {
    clickAction: (row: Record<string, unknown>) => this.editQuotation(row['id'] as string, row['status'] as string),
    deleteAction: (row: Record<string, unknown>) => this.deleteQuotation(row['id'] as string, row['status'] as string),
    extraAction2: (row: Record<string, unknown>) => this.cloneQuotation(row as any),
  };

  innerActions = {};

  quotationsTable: any[] = [];

  tableColumns = [
    {
      prop: 'total',
      name: 'Monto',
      columnAlign: 'center',
      maxWidth: 100,
      minWidth: 100,
    },
    {
      prop: 'number',
      name: 'Número',
      columnAlign: 'center',
      maxWidth: 140,
      minWidth: 140,
    },
    {
      prop: 'createdAt',
      name: 'Fecha',
      maxWidth: 150,
      minWidth: 150,
    },
    {
      prop: 'name',
      name: 'Nombre de cliente',
      maxWidth: 200,
      minWidth: 200,
    },
    {
      prop: 'Client',
      name: 'Cliente',
      columnAlign: 'center',
      maxWidth: 150,
      minWidth: 150,
    },
    {
      prop: 'profits',
      name: 'Ganancias',
      columnAlign: 'center',
      maxWidth: 220,
      minWidth: 220,
    },
    {
      prop: 'note',
      name: 'Detalles',
      sortable: true,
    },
  ];

  tablePagination = {
    page: 1,
    limit: 10,
    count: 0,
  };

  quotationId: string | null = null;

  showDeletionModal = false;
  showCloneModal = false;
  isFetchingData = true;
  isExporting = false;

  actionsTooltips = {
    deleteAction: 'Eliminar',
    extraAction2: 'Duplicar',
  };

  statusTranslations: Record<string, string> = {
    CREATED: 'Borrador',
    ISSUED: 'Enviada',
    ACCEPTED: 'Aceptada',
    PAID: 'Pagada',
    CANCELED: 'Cancelada',
  };

  canBeDeleted = false;
  filterLabel: any;
  isTechnician = false;
  technicianId: string | null = null;
  total = 0;
  isLoading = true;
  dialog: any;
  snackBar: any;
  currentCloneRow: Quotation | null = null;
  exportDataConfig: ExportDataConfig | undefined;
  dateFormat = GLOBAL_CONFIG.dateFormat;

  tabMenuOptions = [
    {
      key: 'CREATED',
      label: 'Pendientes',
    },
    {
      key: 'ISSUED',
      label: 'Emitidas',
    },
    {
      key: 'PAID',
      label: 'Pagadas',
    },
    {
      key: 'CANCELED',
      label: 'Canceladas',
    },
  ];
  tabSelected = 'CREATED';
  showTopActions = true;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private ability: Ability,
    private pdfSvc: CreatedPdfService,
    private quotationService: QuotationsService,
    private authService: AuthService,
    private technicianService: TechniciansService,
  ) {
    if (ability.can('delete', 'Quotation')) {
      this.tableColumns.push({
        prop: 'delete',
        name: '',
        sortable: false,
      });
      this.tableColumns.push({
        prop: 'extraAction2',
        name: '',
        sortable: false,
      });
    }
  }

  async ngOnInit() {
    try {
      const me = await firstValueFrom(this.authService.getMe());
      this.isTechnician = this.technicianService.isTechnician(me.role.name);

      if (this.isTechnician) {
        this.tableColumns = this.tableColumns.filter(column => column.prop !== 'User' && column.prop !== 'note');
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.tableColumns.splice(5, 0, { prop: 'User', name: 'Técnico', maxWidth: 150, minWidth: 150 } as any);
      }
      this.isLoading = false;

      this.route.queryParamMap.subscribe(async params => {
        let sortBy = params.get('sortBy');
        const sortOrder = params.get('sortOrder');
        const page = Number(params.get('page'));

        if (sortBy === 'User') {
          sortBy = sortBy + '.name';
        } else if (sortBy === 'Client') {
          sortBy = sortBy + '.number';
        }
        this.buildQuery(sortBy, sortOrder, page);
        if (page) {
          this.tablePagination['page'] = page;
        }
        this.fillTable(sortBy, sortOrder);
      });

      this.canCreate();
      this.setExportationDataConfig();
      await this.fillTable();
    } catch (error) {
      console.error(error);
      this.router.navigate(['/login']);
    }
  }

  ngAfterViewInit() {
    this.searchForm[0].triggerOnEnter = this.searchOnEnter;
    this.searchForm[0].triggerOnBlur = this.searchOnBlur;
  }

  //Search
  buildQuery(sortKey?: string | null, sortOrder?: string | null, page?: number) {
    if (page) {
      Object.assign(this.searchQuery, page);
    }
    if (sortKey) {
      Object.assign(this.searchQuery, { sortKey, sortOrder });
    } else {
      Object.assign(this.searchQuery, { sortKey: 'createdAt', sortOrder: 'desc' });
    }
  }

  searchOnEnter = (evt: any) => {
    if (evt.key === 'Enter') {
      const value = evt.target.value;
      this.updateSearchForm(value);
    }
  };

  searchOnBlur = (evt: any) => {
    this.updateSearchForm(evt.target.value);
  };

  updateSearchForm = (value: string) => {
    this.searchFormId?.form.setValue({ [this.searchForm[0].key]: value }, { emitEvent: true });
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsDirty();
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsTouched();
    this.search();
  };

  search = async () => {
    this.isFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        const quotations = await this.quotationService.getAllQuotations(this.searchQuery);
        this.total = quotations.total || 0;

        this.quotationsTable = quotations.data.map(quotation => {
          return {
            id: quotation.id,
            total: `$${quotation.total}`,
            number: quotation.numberStr,
            createdAt: moment.utc(quotation.createdAt).format(this.dateFormat),
            name: quotation.Client.name,
            Client: quotation.Client.number,
            User: quotation.User.name,
            profits: `$${quotation.profits}`,
            note: quotation.note,
          };
        });

        this.tablePagination = { page: quotations.page, limit: quotations.limit, count: quotations.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isFetchingData = false;
    });
  };

  async searchFormChanges(evt: { query: string } | unknown) {
    if ((evt as { query: string }).query) {
      this.searchQuery = {
        ...this.searchQuery,
        ['q']: (evt as { query: string }).query,
      };
    } else {
      this.resetSearchQuery();
    }
  }

  resetSearchQuery() {
    delete this.searchQuery['q'];
    this.searchQuery = {
      ...this.searchQuery,
      page: 1,
      limit: 10,
    };
    if (this.technicianId) {
      Object.assign(this.searchQuery, { userId: this.technicianId });
    }
  }

  resetSort() {
    delete this.searchQuery['sortKey'];
    delete this.searchQuery['sortOrder'];
    this.router.navigate(['/private/quotations']);
  }

  resetTablePagination() {
    this.tablePagination['page'] = 1;
  }

  //CRUD
  editQuotation = (quotationId: string, status: string) => {
    if (status == 'CREATED') {
      this.router.navigate(['/private/quotations/edit'], { queryParams: { quotationId: quotationId } });
    } else if (status == 'ISSUED') {
      this.router.navigate(['/private/quotations/view-pdf'], { queryParams: { quotationId: quotationId } });
    } else {
      this.router.navigate(['/private/quotations/view-pdf'], { queryParams: { quotationId: quotationId } });
    }
  };

  cloneQuotation(row: Quotation) {
    this.currentCloneRow = row;
    this.showCloneModal = true;
  }

  getNextQuotationNumber(): number {
    return this.quotationsTable.length + 1;
  }

  addQuotation = () => {
    this.router.navigate(['/private/quotations/new']);
  };

  deleteQuotation(quotationId: string, status: string) {
    if (status === 'CREATED' || status === 'CANCELED') {
      this.canBeDeleted = true;
    }
    this.quotationId = quotationId;
    this.showDeletionModal = true;
  }

  cancelDeletion = () => {
    this.quotationId = null;
    this.showDeletionModal = false;
    this.canBeDeleted = false;
  };

  cancelClone = () => {
    this.showCloneModal = false; // Ocultar el modal de clonación
  };

  confirmDeletion = () => {
    if (this.quotationId && this.quotationId !== '') {
      this.quotationService
        .deleteQuotation(this.quotationId)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Cotización eliminada');
          // Actualiza la tabla después de eliminar la cotización
          this.fillTable();
          this.showDeletionModal = false;
          // Elimina la referencia a la cotización eliminada
          this.quotationId = null;
          this.canBeDeleted = false;
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    }
  };

  confirmCloning = async () => {
    const quotation = await this.quotationService.getQuotationById(this.currentCloneRow?.id || '');
    const formData: QuotationForm = {
      note: quotation?.note || '',
      clientId: quotation?.clientId || '',
      userId: quotation?.userId || '',
      products:
        quotation?.products.map((product: any) => ({
          id: product.product.id || '',
          quantity: product.quantity,
          totalPrice: product.totalPrice,
          unitPrice: product.unitPrice,
        })) || [],
    };
    this.quotationService
      .createQuotation(formData)
      .then(() => {
        this.checkDuplicateSuccessMessage('', 'Cotización duplicada');
        this.showCloneModal = false;
        this.quotationId = null;
        this.canBeDeleted = false;
        this.fillTable();
      })
      .catch(err => {
        console.error(err);
        if (err.error.code) {
          this.checkDuplicateErrorMessage('Error', err.error.code);
        } else {
          this.checkDuplicateErrorMessage('Error', err.error.message);
        }
      });
  };

  onDeleteModalStatus(evt: { isOpen: boolean }) {
    this.showDeletionModal = evt.isOpen;
  }

  onCloneModalConfirmation(evt: { isOpen: boolean }) {
    this.showCloneModal = evt.isOpen;
  }

  // Error Messages

  checkDuplicateErrorMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  // Tabs

  onClickTab() {
    this.showTopActions = this.tabSelected === 'CREATED';
    this.searchFormId?.resetForm();
    this.resetSort();
    this.resetTablePagination();
    this.searchQuery = {
      page: 1,
      limit: 10,
      sortKey: 'createdAt',
      sortOrder: 'desc',
      status: this.tabSelected,
    };
    this.fillTable();
  }

  //Table
  async fillTable(sortKey?: string | null, sortOrder?: string | null) {
    this.isFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        let quotations: any;

        if (this.technicianId) {
          quotations = await this.quotationService.getAllQuotations({
            userId: this.technicianId,
            page: this.tablePagination.page,
            limit: this.tablePagination.limit,
            sortKey: sortKey ? sortKey : 'createdAt',
            sortOrder: sortOrder ? sortOrder : 'desc',
            status: this.tabSelected,
          });
        } else {
          quotations = await this.quotationService.getAllQuotations({
            page: this.tablePagination.page,
            limit: this.tablePagination.limit,
            sortKey: sortKey ? sortKey : 'createdAt',
            sortOrder: sortOrder ? sortOrder : 'desc',
            status: this.tabSelected,
          });
        }
        this.total = quotations.total;

        this.quotationsTable = quotations.data.map((quotation: Quotation) => {
          return {
            id: quotation.id,
            status: quotation.status,
            total: `$${quotation.total}`,
            number: quotation.numberStr,
            createdAt: moment.utc(quotation.createdAt).format('DD MMM yyyy'),
            name: quotation.Client?.name ?? '-',
            Client: quotation.Client?.number ?? '-',
            User: quotation.User.name,
            profits: `$${quotation.profits}`,
            note: quotation.note,
          };
        });

        this.tablePagination = { page: quotations.page, limit: quotations.limit, count: quotations.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isFetchingData = false;
    });
  }

  handleChangePage(page: number) {
    this.tablePagination.page = page;
    this.router.navigate(['/private/quotations'], { queryParams: { page }, queryParamsHandling: 'merge' });
    if (this.searchQuery['q']) {
      this.search();
    }
  }

  onSortTable(evt: { dir: string; column: string }) {
    const col = evt.column === 'name' ? 'Client.name' : evt.column;
    this.tablePagination.page = 1;
    this.router
      .navigate(['/private/quotations'], {
        queryParams: { sortBy: col, sortOrder: evt.dir, page: 1 },
        queryParamsHandling: 'merge',
      })
      .then(() => this.setExportationDataConfig())
      .catch(err => console.error(err));
  }

  translateStatus(statusKey: string) {
    return this.statusTranslations[statusKey];
  }

  //Permission
  canCreate() {
    this.userCanCreate = this.ability.can('create', 'Quotation');
  }

  canUpdate() {
    if (this.ability.cannot('update', 'Quotation')) {
      this.userCanUpdate = false;
      this.showAcceptButton = true;
    } else {
      this.userCanUpdate = true;
      this.showAcceptButton = false;
    }
  }

  //Report
  /**
   * @deprecated
   */
  handleCreateReport = async () => {
    this.isExporting = true;
    this.searchQuery['limit'] = 0;
    const quotations = await this.quotationService.getAllQuotations(this.searchQuery);
    const quotationReport = quotations.data.map(quotation => {
      return {
        total: `$${quotation.total}`,
        number: quotation.numberStr,
        createdAt: moment.utc(quotation.createdAt).format(this.dateFormat),
        name: quotation.Client.name,
        Client: quotation.Client.number,
        User: quotation.User.name,
        profits: `$${quotation.profits}`,
        note: quotation.note,
      };
    });
    const pdfDefinition: any = this.pdfSvc.createdReportQuotation(quotationReport);
    pdfMake.createPdf(pdfDefinition).download('Cotizaciones.pdf');
    this.isExporting = false;
    this.searchQuery['limit'] = 10;
  };

  async setExportationDataConfig(key?: string) {
    this.searchQuery.status = key ?? this.tabSelected;
    this.exportDataConfig = { ...exportDataConfig };
    this.exportDataConfig.data = await this.getReportData();
    this.exportDataConfig.fileName = 'Cotizaciones';
    this.exportDataConfig.config.columns = this.getReportColumns();
    this.exportDataConfig.config.pdfConfigOptions = { pdfColumnWidth: [] };
    let widths = [40, 40, 80, 100, 40, 100, 60, 200];
    if (this.isTechnician) {
      widths = [40, 40, 80, 130, 60, 60];
    }
    this.exportDataConfig.config.pdfConfigOptions.pdfColumnWidth = widths;
    this.exportDataConfig.config.pdfConfigOptions.pdfHeaderFontSize = 10;
    this.exportDataConfig.config.pageOritation = this.isTechnician ? 'portrait' : 'landscape';
    this.exportDataConfig.config.headerLines = [
      {
        text: `Estatus: ${this.translateStatus(this.tabSelected)}`,
        style: 'header',
        alignment: 'left',
      },
    ];
  }

  async getReportData() {
    const query = { ...this.searchQuery };
    query['limit'] = 0;
    const quotations = await this.quotationService.getAllQuotations(query);
    const quotationReport = quotations.data.map(quotation => {
      const row: any = {
        total: `$${quotation.total}`,
        number: quotation.numberStr,
        createdAt: moment.utc(quotation.createdAt).format(this.dateFormat),
        name: quotation.Client?.name ?? '-',
        User: quotation.User?.name ?? '-',
        Client: quotation.Client?.number ?? '-',
        profits: `$${quotation.profits}`,
        note: quotation.note && quotation.note !== '' ? quotation.note : '-',
      };
      if (this.isTechnician) {
        delete row.User;
        delete row.note;
      }
      return row;
    });
    return quotationReport;
  }

  private getReportColumns() {
    const columns = this.tableColumns
      .map(col => {
        const reportCol = {
          header: col.name,
          key: col.prop,
          width: 20,
          style: {
            alignment: {
              horizontal: 'left' as
                | 'center'
                | 'right'
                | 'fill'
                | 'left'
                | 'justify'
                | 'centerContinuous'
                | 'distributed'
                | undefined,
            },
          },
        };

        if (reportCol.key === 'total' || reportCol.key === 'profits') {
          const propertyValue = {
            alignment: {
              horizontal: 'right' as
                | 'center'
                | 'right'
                | 'fill'
                | 'left'
                | 'justify'
                | 'centerContinuous'
                | 'distributed'
                | undefined,
            },
          };
          Object.defineProperty(reportCol, 'style', {
            value: propertyValue,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        if (reportCol.key === 'createdAt') {
          const propertyValue = {
            alignment: {
              horizontal: 'center' as
                | 'center'
                | 'right'
                | 'fill'
                | 'left'
                | 'justify'
                | 'centerContinuous'
                | 'distributed'
                | undefined,
            },
          };
          Object.defineProperty(reportCol, 'style', {
            value: propertyValue,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        return reportCol;
      })
      .filter(col => col.key !== 'delete' && col.key !== 'extraAction2');
    return columns;
  }
}
