<div>
    <ng-template #container></ng-template>
    <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
        <div style="display: flex;gap: 24px;width: 100%;justify-content: flex-start;">
            <p-button *ngIf="showPreviousButton()" variant="outline" theme="secondary" class="wizard-button previous-button" [callback]="onPrevious" > 
                <span *ngIf="!isMobile">Anterior</span>
                 <img *ngIf="isMobile" src="../../../assets/icons/previous.svg" width="30">
            </p-button>
        </div>
        <div style="display: flex;gap: 24px;width: 100%;justify-content: flex-end;">
            <p-button *ngIf="showFinishButton()" variant="outline"  theme="primary" class="wizard-button" [callback]="onFinish"> Finalizar </p-button>
            <p-button *ngIf="showNextButton()" variant="solid"  theme="secondary" class="wizard-button" [callback]="onNext"> Siguiente </p-button>
        </div>
      </div>
</div>