<prt-modal [displayButton]="false" size="md" [isActive]="show"
    (onAction)="modalStatusChange($event)" [hasCloseButton]="false">
    <div class="modal-wrapper">
        <h2>Seleccione la categoría del producto que desea agregar</h2>
        <div class="create__form-wrapper">
            <div class="create__form-box">
                <p-table 
                    *ngIf="loadingData"
                    [rows]="rows" 
                    [columns]="columns"
                    [pagination]="pagination"
                    [innerActions]="innerActions"
                    [isLoading]="!loadingData"
                    [showLoading]="fetchingData"
                    [resultLabel]="'Categorías'" columnMode="force" [showActionsAtStart]="true"
                    [staticRowActions]="true" 
                    [hasTopActions]="true" 
                    [topActionsWithLabels]="true"
                    [hasSearch]="true"
                    [searchForm]="searchForm"
                    [enableAutoSearch]="true"
                    (changeCurrentPage)="changePage($event)"
                    (onSorting)="onSort($event)"
                    (onSearchChange)="onSearchChange($event)"
                    >
                </p-table>
            </div>
        </div>
        <div class="modal-buttons-wrapper--absolute">
            <prt-button [isInverted]="true" type="outline" [callback]="closeModal"> Cancelar
            </prt-button>
            <!-- <prt-button [isInverted]="true" type="solid" [callback]="selectCategory"> Aceptar </prt-button> -->
        </div>
    </div>
</prt-modal>