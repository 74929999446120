import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormUtilsService {
  constructor() {
    //
  }

  /**
   * Toggles the validators for a specific form control within a form group.
   *
   * @param formGroup - The FormGroup containing the control to be validated.
   * @param control - The name of the control within the form group.
   * @param value - A string that determines whether to add or remove the required validator.
   *                If the value is 'true', the required validator is added.
   *                Otherwise, the validators are cleared.
   */
  toggleValidators(formGroup: FormGroup, control: string, value: string) {
    const formControl = formGroup.get(control);
    if (formControl) {
      if (value === 'true') {
        formControl.setValidators([Validators.required]);
      } else {
        formControl.clearValidators();
      }
      formControl.updateValueAndValidity();
    }
  }
}
