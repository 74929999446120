import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from 'src/shared/shared.module';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors';
import { UsersComponent } from './views/users/users.component';
import { UniversalStorage } from './services';
import { AuthGuard } from './guards/auth.guard';
import { RolesComponent } from './views/roles/roles.component';
import { AbilityModule } from '@casl/angular';
import { Ability, PureAbility } from '@casl/ability';
import { TextSeparatorPipe } from './pipes/text-separator.pipe';
import { DragDirective } from './directives/drag-directive.directive';
import { TechniciansComponent } from './views/technicians/technicians.component';
import { TechniciansCreateComponent } from './views/technicians-create/technicians-create.component';
import { ClientsComponent } from './views/clients/clients.component';
import { ClientsCreateComponent } from './views/clients-create/clients-create.component';
import { ProductsComponent } from './views/products/products.component';
import { QuotationsComponent } from './views/quotations/quotations.component';
import { QuotationsCreateComponent } from './views/quotations-create/quotations-create.component';
import { QuotationsSendComponent } from './views/quotations-send/quotations-send.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { QuotationsPublicComponent } from './views/quotations-public/quotations-public.component';
import { UsersCreateComponent } from './views/users-create/users-create.component';
import { RolesCreateComponent } from './views/roles-create/roles-create.component';
import { ProfileComponent } from './views/profile/profile.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { DynamicScriptDirective } from './directives/dynamic-script/dynamic-script.directive';
import { CheckPaymentComponent } from './views/check-payment/check-payment.component';
import { WsFloatingButtonComponent } from './components/ws-floating-button/ws-floating-button.component';
import { MomentConfigService } from './services/moment-config/moment-config.service';
import { WizardComponent } from './components/wizard/wizard.component';
import { CategoriesTableModalComponent } from './components/categories-table-modal/categories-table-modal.component';
import { PdfViewerComponent } from './views/pdf-viewer/pdf-viewer.component';
import { Step0Component } from './components/wizard-steps-components/step0/step0.component';
import { Step1Component } from './components/wizard-steps-components/step1/step1.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LoadingComponent } from './components/loading/loading.component';
import { Step2Component } from './components/wizard-steps-components/step2/step2.component';
import { Step3Component } from './components/wizard-steps-components/step3/step3.component';
import { Step4Component } from './components/wizard-steps-components/step4/step4.component';
import { Step5Component } from './components/wizard-steps-components/step5/step5.component';
import { TranslateHttpLoader } from '@protostech/protos-lib/v2/services/translation/translation.module';

/* export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
 */
@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    UsersComponent,
    RolesComponent,
    TextSeparatorPipe,
    DragDirective,
    TechniciansComponent,
    TechniciansCreateComponent,
    ClientsComponent,
    ClientsCreateComponent,
    ProductsComponent,
    QuotationsComponent,
    QuotationsCreateComponent,
    QuotationsSendComponent,
    PublicLayoutComponent,
    QuotationsPublicComponent,
    UsersCreateComponent,
    RolesCreateComponent,
    ProfileComponent,
    DynamicScriptDirective,
    CheckPaymentComponent,
    WsFloatingButtonComponent,
    WizardComponent,
    CategoriesTableModalComponent,
    PdfViewerComponent,
    Step0Component,
    Step1Component,
    LoadingComponent,
    Step2Component,
    Step3Component,
    Step4Component,
    Step5Component,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
    }),
    BrowserAnimationsModule,
    AbilityModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    /* TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }), */
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true,
    },
    UniversalStorage,
    AuthGuard,
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability },
    MomentConfigService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
