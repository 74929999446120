<h3>Selección de Tuberías</h3>
<form *ngIf="formGroup" [formGroup]="formGroup">
    <prt-form-group>
        <prt-form-field size="lg">
            <prt-label>{{ labels.pipesType }}</prt-label>
            <prt-dropdown formControlName="pipesType" [options]="pipeTypeOptions" prtHostControl></prt-dropdown>
        </prt-form-field>
        <prt-form-field size="lg">
            <prt-label>{{ labels.pipesJoined }}</prt-label>
            <prt-radio-button formControlName="pipesJoined" [options]="pipesGoTogetherOptions" prtHostControl></prt-radio-button>
        </prt-form-field>
        <prt-form-field size="lg">
            <prt-label>{{ labels.pipe1Diameter }}</prt-label>
            <prt-dropdown formControlName="pipe1Diameter" [options]="pipeDiameter1Options" prtHostControl></prt-dropdown>
        </prt-form-field>
        <prt-form-field size="lg">
            <prt-label>{{ labels.pipe2Diameter }}</prt-label>
            <prt-dropdown formControlName="pipe2Diameter" [options]="pipeDiameter2Options" prtHostControl></prt-dropdown>
        </prt-form-field>
    </prt-form-group>
</form>