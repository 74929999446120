import { Injectable } from '@angular/core';
import endpoints from 'src/app/constants/endpoints';
import { Quotation, QuotationForm } from 'src/app/models/quotation';
import { BaseRequest } from 'src/app/models/request';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class QuotationsService {
  constructor(private apiService: ApiService) {}

  getAllQuotations(query?: object): Promise<BaseRequest<Quotation>> {
    return this.apiService.get<BaseRequest<Quotation>>(endpoints.QUOTATIONS, query);
  }

  getQuotationById(id: string): Promise<Quotation> {
    return this.apiService.get<Quotation>(`${endpoints.QUOTATIONS}/${id}`);
  }

  updateQuotation(quotation: Partial<Quotation>): Promise<Quotation> {
    const { id } = quotation;
    delete quotation.id;
    let k: keyof typeof quotation;
    for (k in quotation) {
      if (quotation[k] === '' && k !== 'note') {
        delete quotation[k];
      }
    }
    return this.apiService.put<Quotation>(`${endpoints.QUOTATIONS}/${id}`, quotation);
  }

  deleteQuotation(id: string): Promise<Quotation> {
    return this.apiService.delete<Quotation>(`${endpoints.QUOTATIONS}/${id}`);
  }

  createQuotation(values: QuotationForm) {
    if (values.status) {
      delete values.status;
    }
    let k: keyof typeof values;
    for (k in values) {
      if (values[k] === '' && k !== 'note') {
        delete values[k];
      }
    }
    return this.apiService.post(endpoints.QUOTATIONS, values);
  }

  createQuotationToShare(id: string) {
    return this.apiService.post(`${endpoints.QUOTATIONS}/${id}/send`, { showDetail: true });
  }

  wizard(data: any) {
    return this.apiService.post(`${endpoints.QUOTATIONS}/wizard`, data);
  }

  cancelQuotation(id: string) {
    return this.apiService.post(`/public${endpoints.QUOTATIONS}/${id}/cancel`, { showDetail: true });
  }

  markAsPaid(id: string) {
    return this.apiService.post(`${endpoints.QUOTATIONS}/${id}/mark-paid`, {});
  }

  acceptQuotation(id: string) {
    return this.apiService.post(`/public${endpoints.QUOTATIONS}/${id}/accept`);
  }

  prepareQuotationPayment(id: string) {
    return this.apiService.post(`/public${endpoints.QUOTATIONS}/${id}/payment/prepare`);
  }

  checkQuotationPayment(id: string) {
    return this.apiService.post(`/public${endpoints.QUOTATIONS}/${id}/payment/check`);
  }

  getQuotationPublicById(id: string): Promise<Quotation> {
    return this.apiService.get<Quotation>(`/public${endpoints.QUOTATIONS}/${id}`);
  }

  issue(id: string) {
    return this.apiService.post(`${endpoints.QUOTATIONS}/${id}/issue`);
  }

  getPdf(id: string) {
    return this.apiService.getPdf(`${endpoints.QUOTATIONS}/${id}/pdf`, undefined);
  }
}
