import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { labelsStep4, yesOrNotOptions } from './step4.config';
import { Subject, takeUntil } from 'rxjs';
import { PrtOptionDropdownModel, PrtOptionRadioButtonModel } from '@protostech/protos-lib/v2';
import { FormUtilsService } from 'src/app/services/utils/form-utils.service';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'app-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.scss'],
})
export class Step4Component implements OnInit {
  labels = labelsStep4;
  yesOrNotOptions: PrtOptionRadioButtonModel[] = yesOrNotOptions;
  condensatePumpsModelOptions: PrtOptionDropdownModel[] = [];
  showInputs = {
    condensatePumpsAmount: false,
    condensatePumpsModel: false,
    needCondensatePumps: false,
  };
  destroy$ = new Subject<void>();

  constructor(
    private formUtilsService: FormUtilsService,
    private productsService: ProductsService,
    public formGroup: FormGroup,
  ) {
    //
  }

  ngOnInit(): void {
    this.subscribeToEvent();
    this.fillForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async fillForm() {
    const needCondensatePumps = this.formGroup.get('needCondensatePumps');
    if (needCondensatePumps?.value) {
      this.changeCondensatePumpsAmount(needCondensatePumps.value);
      this.changeCondensatePumpsModel(needCondensatePumps.value);
    }
    await this.fillCondensatePumpsModel();
    this.showInputs.needCondensatePumps = this.condensatePumpsModelOptions.length > 0;
    this.showCondensatePumpsInputs(needCondensatePumps?.value);
  }

  private subscribeToEvent() {
    this.onNeedCondensatePumpsChange();
  }

  private onNeedCondensatePumpsChange() {
    const needCondensatePumps = this.formGroup.get('needCondensatePumps');
    needCondensatePumps?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(async value => {
      this.changeCondensatePumpsAmount(value);
      this.changeCondensatePumpsModel(value);
      this.showCondensatePumpsInputs(value);
    });
  }

  private changeCondensatePumpsAmount(value: string) {
    this.formUtilsService.toggleValidators(this.formGroup, 'condensatePumpsAmount', value);
  }

  private async changeCondensatePumpsModel(value: string) {
    if (value === 'true') {
      await this.fillCondensatePumpsModel();
    } /* else {
      this.condensatePumpsModelOptions = [];
    } */
    this.formUtilsService.toggleValidators(this.formGroup, 'condensatePumpsModel', value);
  }

  private showCondensatePumpsInputs(value: string) {
    this.showInputs.condensatePumpsModel = value === 'true';
    this.showInputs.condensatePumpsAmount = value === 'true';
  }

  private async fillCondensatePumpsModel() {
    const response = await this.productsService.getCondensatePumps();
    console.log(response);
    this.condensatePumpsModelOptions = response.map((item: any) => ({ label: item.name, value: item.id }));
  }
}
