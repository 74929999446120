<h3>Selección de Aire Acondicionado</h3>
<form *ngIf="formGroup" [formGroup]="formGroup">
    <prt-form-group>
        <prt-form-field size="xl">
            <prt-label>
                <span>{{ labels.brand }}</span>
            </prt-label>
            <prt-dropdown formControlName="brand" [options]="markOptions" prtHostControl></prt-dropdown>
        </prt-form-field>
        <prt-form-field size="xl">
            <prt-label>
                <span>{{ labels.airConditionerId }}</span>
            </prt-label>
            <prt-dropdown formControlName="airConditionerId" [options]="modelOptions" prtHostControl></prt-dropdown>
        </prt-form-field>
        <prt-form-field size="lg">
            <prt-label>{{ labels.capacity }}</prt-label>
            <prt-input prtHostControl formControlName="capacity" [placeholder]="placeholders.capacity"></prt-input>
        </prt-form-field>
        <prt-form-field size="lg">
            <prt-label><span title="(Condensador - Evaporador)">{{ labels.airConditionerFeets }}</span></prt-label>
            <prt-input prtHostControl formControlName="airConditionerFeets" [placeholder]="placeholders.airConditionerFeets"></prt-input>
        </prt-form-field>
    </prt-form-group>
</form>