import { Component, OnInit } from '@angular/core';
import { labelsStep5, yesOrNotOptions } from './step5.config';
import { FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { FormUtilsService } from 'src/app/services/utils/form-utils.service';

@Component({
  selector: 'app-step5',
  templateUrl: './step5.component.html',
  styleUrls: ['./step5.component.scss'],
})
export class Step5Component implements OnInit {
  labels = labelsStep5;
  yesOrNotOptions = yesOrNotOptions;
  showWeldingRodsAmount = false;
  destroy$ = new Subject<void>();

  constructor(private formUtilsService: FormUtilsService, public formGroup: FormGroup) {
    //
  }

  ngOnInit(): void {
    this.subscribeToEvent();
    this.fillForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private fillForm() {
    const needWeldingRods = this.formGroup.get('needWeldingRods');
    if (needWeldingRods?.value) {
      this.changeWeldingRodsAmount(needWeldingRods.value);
    }
  }

  private subscribeToEvent() {
    this.onNeedWeldingRodsChange();
  }

  private onNeedWeldingRodsChange() {
    const needWeldingRods = this.formGroup.get('needWeldingRods');
    needWeldingRods?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.changeWeldingRodsAmount(value);
    });
  }

  private changeWeldingRodsAmount(value: string) {
    if (value === 'true') {
      this.showWeldingRodsAmount = true;
    } else {
      this.showWeldingRodsAmount = false;
    }
    this.formUtilsService.toggleValidators(this.formGroup, 'weldingRodsAmount', value);
  }
}
