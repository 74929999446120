<h3>Complementos Adicionales 1</h3>
<form *ngIf="formGroup" [formGroup]="formGroup">
    <prt-form-group>
        <prt-form-field size="xl">
            <prt-label>
                <span>{{ labels.needSurgeProtector }}</span>
            </prt-label>
            <prt-radio-button formControlName="needSurgeProtector" [options]="yesOrNotOptions"
                prtHostControl></prt-radio-button>
        </prt-form-field>
        <prt-form-field size="xl">
            <prt-label>
                <span>{{ labels.needPowerCable }}</span>
            </prt-label>
            <prt-radio-button formControlName="needPowerCable" [options]="yesOrNotOptions"
                prtHostControl></prt-radio-button>
        </prt-form-field>
        <prt-form-field *ngIf="showInputs.needCondensatePumps" size="lg">
            <prt-label>
                <span>{{ labels.needCondensatePumps }}</span>
            </prt-label>
            <prt-radio-button prtHostControl formControlName="needCondensatePumps"
                [options]="yesOrNotOptions"></prt-radio-button>
        </prt-form-field>
        <prt-form-field *ngIf="showInputs.condensatePumpsAmount" size="lg">
            <prt-label>
                <span>{{ labels.condensatePumpsAmount }}</span>
            </prt-label>
            <prt-input formControlName="condensatePumpsAmount" prtHostControl></prt-input>
        </prt-form-field>
        <prt-form-field *ngIf="showInputs.condensatePumpsModel" size="xl">
            <prt-label>
                <span>{{ labels.condensatePumpsModel }}</span>
            </prt-label>
            <prt-dropdown formControlName="condensatePumpsModel" [options]="condensatePumpsModelOptions"
                prtHostControl></prt-dropdown>
        </prt-form-field>
    </prt-form-group>
</form>