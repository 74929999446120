import { Validators } from '@angular/forms';

export const labelsStep0 = {
  brand: 'Marca',
  airConditionerId: 'Modelo',
  capacity: 'Capacidad',
  airConditionerFeets: 'Distancia (Cond. - Evap.)',
};

export const placeholders = {
  capacity: 'BTU',
  airConditionerFeets: 'Pies',
};

export const step0Controls = {
  brand: ['', Validators.required],
  airConditionerId: ['', [Validators.required]],
  capacity: ['', Validators.required],
  airConditionerFeets: ['', [Validators.required]],
};
