import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { labelsStep1, pipesGoTogetherOptions } from './step1.config';
import { Subject } from 'rxjs';
import { PrtOptionDropdownModel, PrtOptionRadioButtonModel } from '@protostech/protos-lib/v2';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss'],
})
export class Step1Component implements OnInit {
  labels = labelsStep1;
  destroy$ = new Subject<void>();
  pipesGoTogetherOptions: PrtOptionRadioButtonModel[] = pipesGoTogetherOptions;
  pipeTypeOptions: PrtOptionDropdownModel[] = [];
  pipeDiameter1Options: PrtOptionDropdownModel[] = [];
  pipeDiameter2Options: PrtOptionDropdownModel[] = [];

  constructor(public formGroup: FormGroup, private productsService: ProductsService) {
    //
  }

  ngOnInit(): void {
    this.subscribeToEvents();
    this.fillDropdowns();
    this.fillForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private subscribeToEvents(): void {
    this.pipeTypeChanges();
  }

  private pipeTypeChanges() {
    const pipesType = this.formGroup.get('pipesType');
    pipesType?.valueChanges.subscribe(value => {
      this.changePipeDiameters(value);
    });
  }

  private changePipeDiameters(value: string) {
    if (value && value !== '') {
      this.fillPipeDiameter1(value);
      this.fillPipeDiameter2(value);
    } else {
      this.pipeDiameter1Options = [];
      this.pipeDiameter2Options = [];
    }
  }

  private fillForm() {
    this.changePipeDiameters(this.formGroup.get('pipesType')?.value);
  }

  private fillDropdowns(): void {
    this.fillPipeType();
  }

  private async fillPipeType() {
    const response = await this.productsService.getPipeTypes();
    this.pipeTypeOptions = response.map(pipesType => ({
      label: pipesType,
      value: pipesType,
    }));
  }

  private fillPipeDiameter1(pipeTypeValue: string) {
    this.pipeDiameter1Options = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
    ];
  }

  private fillPipeDiameter2(pipeTypeValue: string) {
    this.pipeDiameter2Options = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
    ];
  }
}
