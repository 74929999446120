import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TextBoxField } from '@protostech/protos-lib';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'src/app/models/categories';
import { CategoriesService } from 'src/app/services/categories/categories.service';

export interface CategoryRow {
  id: string;
  name: string;
}

@Component({
  selector: 'app-categories-table-modal',
  templateUrl: './categories-table-modal.component.html',
  styleUrls: ['./categories-table-modal.component.scss'],
})
export class CategoriesTableModalComponent implements OnInit, OnDestroy {
  fetchingData = false;
  loadingData = false;
  selectedCategory?: CategoryRow;
  rows: any[] = [];
  private lastClickedElement: HTMLElement | null = null;
  query: any = {
    sortKey: 'name',
    sortOrder: 'asc',
    page: 1,
    limit: 0,
  };
  pagination = {
    page: 1,
    limit: 0,
    count: 0,
  };
  columns = [
    {
      prop: 'name',
      name: 'Categoría',
      sortable: false,
    },
  ];
  innerActions: Record<string, unknown> = {
    clickAction: (row: CategoryRow) => this.clickAction(row),
  };
  searchForm: TextBoxField[] = [
    {
      controlType: 'textBox',
      key: 'query',
      type: 'text',
      value: '',
      label: '',
      required: false,
      width: 'lg',
      noMargin: true,
      showInnerIcon: true,
      icon: '../assets/icons/search.svg',
      position: 'center',
      isSingleRow: true,
      placeholder: 'Escriba su búsqueda',
    },
  ];
  @Input() show = false;
  @Output() onSelectCategory = new EventEmitter<CategoryRow>();
  @Output() onModalStatusChange = new EventEmitter<{ isOpen: boolean }>();

  constructor(private categoriesServices: CategoriesService, private toastr: ToastrService) {}

  async ngOnInit(): Promise<void> {
    await this.filltable();
  }

  ngOnDestroy(): void {
    this.selectedCategory = undefined;
  }

  private clickAction(row: CategoryRow) {
    this.selectedCategory = row;
    this.selectCategory();
  }

  private getRow(category: Category): CategoryRow {
    return {
      id: category.id,
      name: category.name,
    };
  }

  private async filltable() {
    const response = await this.categoriesServices.getAllCategories(this.query);
    this.rows = response.data.map(row => this.getRow(row));
    this.pagination = { page: response.page, limit: response.limit, count: response.count };
    this.loadingData = true;
  }

  modalStatusChange = (evt: { isOpen: boolean }) => {
    this.onModalStatusChange.emit(evt);
    this.show = evt.isOpen;
  };

  changePage(page: number) {
    this.pagination.page = page;
  }

  closeModal = () => {
    this.show = false;
    this.onModalStatusChange.emit({ isOpen: false });
  };

  onSort(evt: { dir: string; column: string }) {
    this.pagination.page = 1;
    this.query = { ...this.query, sortKey: evt.column, sortOrder: evt.dir };
    this.filltable();
  }

  onSearchChange(evt: any | unknown) {
    this.query = {
      ...this.query,
      page: 1,
      limit: 0,
    };
    if (evt && evt['query'] && evt['query'] !== '') {
      this.query['name'] = evt['query'];
    } else {
      delete this.query['name'];
    }
    this.filltable();
  }

  selectCategory = () => {
    if (this.selectedCategory) {
      this.show = false;
      this.onSelectCategory.emit(this.selectedCategory);
      this.onModalStatusChange.emit({ isOpen: false });
    } else {
      this.toastr.error('Debe seleccionar una categoría.');
    }
  };

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    console.log('click');
    const target = event.target as HTMLElement;
    console.log(target.classList, target.classList.contains('non-static-column'));
    // Si el elemento clicado tiene la clase `clickable`
    if (target.classList.contains('non-static-column') || target.classList.contains('p-table__status-wrapper')) {
      // Remover la clase `clicked` del último elemento clicado
      if (this.lastClickedElement) {
        this.lastClickedElement.classList.remove('clicked');
      }
      // Agregar la clase `clicked` al elemento actual
      target.classList.add('clicked');
      console.log(target.classList);
      // Actualizar el último elemento clicado
      this.lastClickedElement = target;
    }
  }
}
