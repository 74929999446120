import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import 'moment/locale/es';

@Injectable({
  providedIn: 'root',
})
export class MomentConfigService {
  constructor() {
    moment.tz.setDefault('America/Panama');
    moment.locale('es');
  }
}
