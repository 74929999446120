import { Validators } from '@angular/forms';

export const labelsStep1 = {
  pipesType: 'Tipo de Tuberías',
  pipesJoined: '¿Las tuberías van juntas?',
  pipe1Diameter: 'Diámetro Tubería 1',
  pipe2Diameter: 'Diámetro Tubería 2',
};

export const step1Controls = {
  pipesType: ['PEX', Validators.required],
  pipesJoined: ['true', Validators.required],
  pipe1Diameter: ['', Validators.required],
  pipe2Diameter: ['', Validators.required],
};

export const pipesGoTogetherOptions = [
  { value: 'true', label: 'Si' },
  { value: 'false', label: 'No' },
];
